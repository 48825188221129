import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/yogurtandjam/Pinwheel/uikit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { UIKitProvider, DescriptionList, DescriptionRow } from "./index";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "description"
    }}>{`Description`}</h1>
    <h2 {...{
      "id": "descriptionlist"
    }}>{`DescriptionList`}</h2>
    <Props of={DescriptionList} mdxType="Props" />
    <h2 {...{
      "id": "descriptionrow"
    }}>{`DescriptionRow`}</h2>
    <Props of={DescriptionRow} mdxType="Props" />
    <UIKitProvider mdxType="UIKitProvider">
    <DescriptionList headers={[{
        value: 'id'
      }, {
        value: 'something'
      }]} title="My Favrite List" rows={[{
        columns: [{
          value: '1'
        }, {
          value: 'hello'
        }]
      }, {
        columns: [{
          value: '2'
        }, {
          value: 'world'
        }]
      }]} mdxType="DescriptionList" />
    </UIKitProvider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      